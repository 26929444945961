var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container join" },
    [
      _c("page-header", { attrs: { title: "회원 타입을 선택해주세요." } }),
      _c("div", {
        staticClass: "ui-border-line ui-mt-4",
        staticStyle: { height: "10px" }
      }),
      _c(
        "div",
        {
          staticClass: "join-selectbox",
          on: {
            click: function($event) {
              return _vm.setCategory("client")
            }
          }
        },
        [
          _c("icon", {
            staticClass: "img",
            attrs: {
              src: "img/join/normalusers.png",
              width: "9.1rem",
              height: "8rem"
            }
          }),
          _c("icon", {
            staticClass: "next",
            attrs: {
              src: "img/common/next-button.png",
              width: "1.3rem",
              height: "8rem",
              cursor: "pointer",
              cover: false
            }
          }),
          _c("p", { staticClass: "desc" }, [_vm._v("도움이 필요해요!")]),
          _c("p", { staticClass: "member-type" }, [_vm._v("일반회원")])
        ],
        1
      ),
      _c("div", { staticClass: "ui-border-line" }),
      _c(
        "div",
        {
          staticClass: "join-selectbox",
          on: {
            click: function($event) {
              return _vm.setCategory("artist")
            }
          }
        },
        [
          _c("icon", {
            staticClass: "img",
            attrs: {
              src: "img/join/pro.png",
              width: "9.8rem",
              height: "9.8rem"
            }
          }),
          _c("icon", {
            staticClass: "next",
            attrs: {
              src: "img/common/next-button.png",
              width: "1.3rem",
              height: "8rem",
              cursor: "pointer",
              cover: false
            }
          }),
          _c("p", { staticClass: "desc" }, [_vm._v("일하고 싶어요!")]),
          _c("p", { staticClass: "member-type" }, [_vm._v("전문가")])
        ],
        1
      ),
      _c("div", { staticClass: "ui-border-line" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }