<template>
  <div class="container join">
    <page-header title="회원 타입을 선택해주세요." />

    <div class="ui-border-line ui-mt-4" style="height: 10px"></div>

    <div class="join-selectbox" @click="setCategory('client')">
      <icon src="img/join/normalusers.png" width="9.1rem" height="8rem" class="img"/>
      <icon src="img/common/next-button.png" width="1.3rem" height="8rem" cursor="pointer" :cover="false" class="next" />
      <p class="desc">도움이 필요해요!</p>
      <p class="member-type">일반회원</p>
    </div>

    <div class="ui-border-line"></div>

    <div class="join-selectbox" @click="setCategory('artist')">
      <icon src="img/join/pro.png" width="9.8rem" height="9.8rem" class="img"/>
      <icon src="img/common/next-button.png" width="1.3rem" height="8rem" cursor="pointer" :cover="false" class="next" />
      <p class="desc">일하고 싶어요!</p>
      <p class="member-type">전문가</p>
    </div>

    <div class="ui-border-line"></div>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'

export default {
  name: 'Join',
  components: {
    PageHeader,
    Icon,
  },
  data() {
    return {
      joinData: {
        sns: null,
        snsUid: null,
        category: null,
        nickName: null,
        userName: null,
        email: null,
        phone: null,
        regNum: null,
        imgUrl: null,
        platform: null,
        eventDate: null,
        referrer: null,
        isAutoSet: false
      },
    }
  },
  computed: {
    query() {
      return this.$route.query
    },
    userData() {
      return this.$store?.state?.user?.userData
    },
    isLogin() {
      return !!this.userData?.useridx
    },
  },
  beforeRouteEnter (to, from, next) {
    next(Vue => cb.call(Vue))

    function cb() {
      if (this.isLogin) {
        this.$router.push('/home')
        return
      }
    }
  },
  mounted() {
    const sns = this.query.sns || localStorage.getItem('sns')
    const snsUid = this.query.snsUid || localStorage.getItem('snsUid')
    const imgUrl = this.query.imgUrl || null
    const platform = this.query.platform || sessionStorage.getItem('platform') || 'webapp'
    const nickName = this.query.nickName || ''
    const referrer = this.query.referrer || sessionStorage.getItem('referrer')
    const memo = this.query.memo || sessionStorage.getItem('initial-query:memo')
    const name = this.query.name || ''
    var phone = this.query.phone || ''

    if(name || phone){
      if (name && name != '') {
              this.joinData.userName = name
              this.joinData.isAutoSet = true
            } 
      if(phone && phone != '') {
       phone = phone.replace(/[^0-9]/g, '')
        if(phone.startsWith('82')){
          phone = '0' + phone.substring(2)
        }
        this.joinData.phone = phone
      }
    }
    Object.assign(this.joinData, { sns, snsUid, imgUrl, platform, nickName, referrer, memo })
  },
  methods: {
    setCategory(category) {
      this.joinData.category = category

      sessionStorage.setItem('joinData', JSON.stringify(this.joinData))
      this.$router.push('/join/input')
      // 20231127 회원 가입 시 관심분야 설정 화면 제거 
      // if (category === 'client') {
      //    this.$router.push('/join/interests')
      // } else {
      //   this.$router.push('/join/input')
      // }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.join-selectbox {
  height: 15rem;
  text-align: left;
  margin: 0 auto;
  padding: 2.5rem;

  .img {
    float: left;
    margin-left: 2rem;
    margin-right: 6rem;
  }

  .desc {
    font-size: 2rem;
    line-height: 3rem;
    color: #b2b2b2;
  }

  .member-type {
    font-size: 3rem;
    line-height: 4rem;
    color: #5a5a5a;
  }

  .next {
    float: right;
  }
}
</style>
